import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

const routes = [
  {
    path: "/",
    name: "analitics",
    component: () => import("@/pages/analitics/orders/index.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.state.navbar.isLoggedIn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/auth/login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.navbar.isLoggedIn) {
        next({ name: "home" });
      } else {
        next();
      }
    },
    meta: {
      requiresHttps: true,
    },
  },
  {
    path: "/registerApp",
    name: "registerApp",
    component: () => import("@/pages/analitics/orders/registerApp.vue"),
  },
  {
    path: "/orderApp",
    name: "orderApp",
    component: () => import("@/pages/analitics/orders/orderApp.vue"),
  },
  {
    path: "/performer",
    name: "performer",
    component: () => import("@/pages/orders/performer/index.vue"),
  },
  {
    path: "/passenger",
    name: "passenger",
    component: () => import("@/pages/orders/passenger/index.vue"),
  },
  {
    path: "/topCities",
    name: "topCities",
    component: () => import("@/pages/analitics/topCities/index.vue"),
  },
  {
    path: "/performerProfile",
    name: "performerProfile",
    component: () => import("@/pages/performer/listPerformer/index.vue"),
  },
  {
    path: "/dailyVisits",
    name: "dailyVisits",
    component: () => import("@/pages/analitics/dailyVisits/index.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/pages/news/index.vue"),
  },
  {
    path: "/blackList",
    name: "blackList",
    component: () => import("@/pages/performer/blackList/index.vue"),
  },
  {
    path: "/versionControl",
    name: "versionControl",
    component: () => import("@/pages/settings/versionControl/index.vue"),
  },
  {
    path: "/slug",
    name: "slug",
    component: () => import("@/pages/analitics/slug/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
