import { checkAuth, getTokenFromLocalstorage } from "@/auth";

export default {
  state: {
    isLoggedIn: checkAuth(),
    token: getTokenFromLocalstorage(),
    menuOpen: false,
    closeMenu: false,
    Open: false,
    openSettings: false,
    selectedSection: "",
    performer: false,
    userMenu: false,
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    menuOpen: (state) => state.menuOpen,
    Open: (state) => state.Open,
    openSettings: (state) => state.openSettings,
    performer: (state) => state.performer,
    userMenu: (state) => state.userMenu,
  },
  mutations: {
    setIsLoggedIn(state, value) {
      console.log(value);

      state.isLoggedIn = value;
      state.token = value;
    },
    setMenuOpen(state, value) {
      state.menuOpen = value;
    },
    setperformerMenu(state, value) {
      state.performer = value;
    },
    setUserMenu(state, value) {
      state.userMenu = value;
    },
    setOpen(state, value) {
      state.Open = value;
    },
    setSetting(state, value) {
      state.openSettings = value;
    },
    setMenu(state, value) {
      state.MenuOpen = value;
    },
    setSelectedSection(state, section) {
      state.selectedSection = section;
    },
  },
  actions: {
    login({ commit }) {
      commit("setIsLoggedIn", true);
    },
    logout({ commit }) {
      commit("setIsLoggedIn", false);
    },
    toggleMenu({ commit, state }) {
      const isOpen = !state.menuOpen;
      commit("setMenuOpen", isOpen);
    },
    performerMenu({ commit, state }) {
      const isOpen = !state.performer;
      commit("setperformerMenu", isOpen);
    },
    userMenu({ commit, state }) {
      const isOpen = !state.userMenu;
      commit("setUserMenu", isOpen);
    },
    openMenu({ commit, state }) {
      const open = !state.Open;
      commit("setOpen", open);
    },
    openSetting({ commit, state }) {
      const openSettings = !state.openSettings;
      commit("setSetting", openSettings);
    },
  },
};
