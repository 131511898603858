<template>
  <teleport to="body">
    <div
      v-if="isOpen"
      :class="
        hidden
          ? 'modal-overlay fixed inset-0 z-40 flex justify-center items-center bg-black bg-opacity-50'
          : ''
      "
    >
      <div
        v-bind="$attrs"
        class="fixed z-40 flex justify-center bg-modal-back w-full"
        :class="classes"
        @click="closeModal($event)"
        ref="modal"
        style="cursor: move; scrollbar-width: thin"
      >
        <div
          class="block bg-white z-40 rounded-[20px] border-[2px] border-[#D9D9D9]"
          :class="classes"
        >
          <div class="flex justify-end m-[10px] mb-2">
            <img
              ref="exit"
              class="bg-gray-50 cursor-pointer"
              @click="$emit('closeModal')"
              src="@/assets/svg/close-line.svg"
              alt=""
            />
          </div>
          <div
            v-if="title"
            class="block pb-[16px] border-b-[2px] border-[#D9D9D9] px-[20px] text-[26px] font-semibold select-none"
          >
            {{ title }}
          </div>
          <div ref="info">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { ref, watch, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "default",
  },
  hidden: {
    type: Boolean,
    default: false,
  },
});
const isOpen = ref(false);
const modal = ref(null);
const exit = ref(null);
const info = ref(null);

const dragModal = () => {
  const ballElement = modal.value;

  ballElement.onmousedown = function (event) {
    if (
      exit.value.contains(event.target) ||
      info.value.contains(event.target)
    ) {
      return;
    }

    let shiftX = event.clientX - ballElement.getBoundingClientRect().left;
    let shiftY = event.clientY - ballElement.getBoundingClientRect().top;

    moveAt(event.clientX, event.clientY);

    function moveAt(clientX, clientY) {
      const documentWidth = document.documentElement.clientWidth;
      const documentHeight = document.documentElement.clientHeight;

      let newX = clientX - shiftX;
      let newY = clientY - shiftY;

      if (newX < 0) newX = 0;
      if (newX + ballElement.offsetWidth > documentWidth)
        newX = documentWidth - ballElement.offsetWidth;

      if (newY < 0) newY = 0;
      if (newY + ballElement.offsetHeight > documentHeight)
        newY = documentHeight - ballElement.offsetHeight;

      ballElement.style.left = newX + "px";
      ballElement.style.top = newY + "px";
    }

    function onMouseMove(event) {
      moveAt(event.clientX, event.clientY);
    }

    document.addEventListener("mousemove", onMouseMove);

    ballElement.onmouseup = function () {
      document.removeEventListener("mousemove", onMouseMove);
      ballElement.onmouseup = null;
    };
  };

  ballElement.ondragstart = function () {
    return false;
  };
};

const closeModal = (event) => {
  if (event.target.classList.contains("modal")) {
    isOpen.value = false;
  }
};
watch(
  () => isOpen.value,
  (newOptions) => {
    if (newOptions) {
      setTimeout(() => dragModal(), 0);
    }
  },
  { immediate: true }
);

const classes = computed(() => {
  return {
    [`modal--${props.size}`]: props.size,
    [` top-0 left-0`]: props.hidden === false,
    [` top-60 right-1/3`]: props.hidden === true,
  };
});

const openModal = () => {
  isOpen.value = true;
};

defineExpose({
  openModal,
  isOpen,
});
</script>

<style scoped>
.modal--md {
  max-width: 676px;
  width: 100%;
}
.modal--sm {
  max-width: 442px;
  width: 100%;
}
.modal--lg {
  max-width: 827px;
  width: 100%;
}
.modal--default {
  max-width: 300px;
  width: 100%;
}
</style>
